<template>
  <div class="cases-wrapper">
    <div class="full-with-banner">
      <Nav nums='3' isColor='#fff'/>
          <div class="homebanner">
           <Video :bannerArr='coverBanner'/>
         </div>
    </div>

    <div class="restrict-content construction-process-wrapper">
      <SectionTitle title="construction" :with-dot="false" :with-highlighted-tail="false"/>
      <SectionTitle title="process"/>
      <SectionSubTitle title="施工流程"/>
      <div class="processes-wrapper pc">
        <swiper class="prize-swiper-wrapper" :options="swiperOptions" style="width:100%,height:50vw">
            <swiper-slide class="prize-image" :class="isTrue?'':'bgimg'" v-for="item in consArr" :key="item.id">
             <div class="process-name">{{item.title}}</div>
             <div class="process-description" @mouseover='placeShow(item.id)' @mouseout='placefalse'>{{item.content}}</div>
             <div class="prizeInfo" v-show="placeIns[item.id]">
              {{item.content}}
              <span class="icon"></span>
            </div>
            </swiper-slide>
        </swiper>
      </div>
      <div class="modile">
        <div class="modile_box" :class="isTrue?'':'bgimg'" v-for="item in consArr" :key="item.id">
          <div class="process-name">{{item.title}}</div>
          <div class="process-description">{{item.content}}</div>
      </div>
      </div>
    </div>
    <div class="restrict-content quality-assurance-wrapper">
      <img class="float-image" src="../../assets/cases/float-gear.png" alt="质量保证">
      <img class="float-image shield" src="../../assets/cases/shield.png" alt="质量保证">
      <SectionTitle title="quality" :with-dot="false" :with-highlighted-tail="false"/>
      <SectionTitle title="assurance"/>
      <SectionSubTitle title="质量保证"/>
      <div class="guarantees-wrapper">
        <div class="guarantee-wrapper">
          <div class="icon"><img src="../../assets/cases/gear.png" alt="质量体系"></div>
          <div class="name">质量体系</div>
          <div class="desc">ISO9001国际质量管理体系认证，ISO13485质量管理体系认证，YY/T0287医疗器械质量管理体系认证，环境管理体系认证，职业健康安全管理体系认证，欧盟CE认证</div>
        </div>
        <div class="guarantee-wrapper">
          <div class="icon"><img src="../../assets/cases/shield.png" alt="售后服务"></div>
          <div class="name">售后服务</div>
          <div class="desc">业务除遍及欧洲、亚洲、美洲、非洲、南美洲、大洋洲，老肯医疗在全国31个省市自治区设有办事处/分子公司负责销售及售后维修服务。</div>
        </div>
      </div>
    </div>
    <div class="scope-of-work-wrapper">
      <div class="scope-of-work-wrapper-background-placeholder"/>
      <div class="restrict-content">
        <SectionTitle title="scope of work"/>
        <SectionSubTitle title="工程范围"/>
        <div class="abilities-wrapper">
          <div class="ability-wrapper">
            <div class="icon"><img src="../../assets/cases/bottle.png" alt="消毒供应中心"></div>
            <div class="name">消毒供应中心<br/>装饰安装工程</div>
          </div>
          <div class="ability-wrapper">
            <div class="icon"><img src="../../assets/cases/surgery.png" alt="洁净手术室"></div>
            <div class="name">洁净手术室<br/>装饰安装工程</div>
          </div>
          <div class="ability-wrapper">
            <div class="icon"><img src="../../assets/cases/alert.png" alt="重症监护病房"></div>
            <div class="name">重症监护病房<br/>装饰安装工程</div>
          </div>
          <div class="ability-wrapper">
            <div class="icon"><img src="../../assets/cases/medic-blood.png" alt="血液透析中心"></div>
            <div class="name">血液透析中心<br/>装饰安装工程</div>
          </div>
        </div>
        <div class="abilities-wrapper">
          <div class="ability-wrapper">
            <div class="icon"><img src="../../assets/cases/surgery-camera.png" alt="内镜中心"></div>
            <div class="name">内镜中心<br/>装饰安装工程</div>
          </div>
          <div class="ability-wrapper">
            <div class="icon"><img src="../../assets/cases/birth-room.png" alt="产房"></div>
            <div class="name">产房<br/>装饰安装工程</div>
          </div>
          <div class="ability-wrapper">
            <div class="icon"><img src="../../assets/cases/iv-center.png" alt="静脉配液中心"></div>
            <div class="name">静脉配液中心<br/>装饰安装工程</div>
          </div>
          <div class="ability-wrapper">
            <div class="icon"><img src="../../assets/cases/microscope.png" alt="实验室"></div>
            <div class="name">实验室<br/>装饰安装工程</div>
          </div>
        </div>
      </div>
    </div>

    <div class="restrict-content demonstrations-wrapper">
      <SectionTitle title="case presentation"/>
      <SectionSubTitle title="案例展示"/>
      <div class="demonstration-columns"  id="caseBox">
        <div class="demonstration-column" v-for="(cs, csi) in casesWithImages" :key="csi">
          <div class="demonstration-wrapper" v-for="c in cs" :key="c.id">
            <router-link :to="{path:'/caseDetail',query:{id:c.id}}">
              <img class="image" :src="c.images" :alt="c.name">
              <div class="desc-wrapper">
                <div class="name">{{ c.name }}</div>
                <div class="brief-desc">{{ c.depict }}</div>
              </div>
            </router-link>
          </div>
          <div v-if="csi === casesWithImages.length - 1 && cases.length > 0" class="more-demos-wrapper">
            <div class="title">还想看更多？</div>
            <div class="sep"/>
            <div class="more-demos">
              <div class="more-demo" v-for="c in cases" :key="c.id">
                <router-link :to="{path:'/caseDetail',query:{id:c.id}}">
                {{c.name}}
                </router-link>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Floor/>
  </div>
</template>

<script>
import NavMixin from '@/mixins/nav-minxin'
import { imgHttp, request } from '@/apis/http'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.min.css'
import Video from '../../components/video.vue'

/**
 * @typedef Case
 * @property {number} id
 * @property {string} name
 * @property {string} depict
 * @property {string} images
 */

// 最多显示多少带图片的案例
const MAX_CASES_WITH_IMAGE = 5

export default {
  components:{ Swiper, SwiperSlide, Video },
  mixins: [NavMixin],
  name: 'Cases',
  beforeMount () {
    this.fetchCaseList()
    this.bigBanner()
    scrollTo(0, 0)
    this.construction()
  },
  data () {
    return {
      /**
       * @type {Case[][]}
       */
      casesWithImages: [],
      /**
       * @type {Case[]}
       */
      cases: [],
      coverBanner:[],
      bannerImg:[],
      swiperOptions: {
        slidesPerView: 4,
        spaceBetween: 0,
        centeredSlides: false,
        loop: false,
        autoplay: false
      },
      consArr:[],
      isTrue:true,
      placeIns:[]
    }
  },
  methods: {
   placeShow(id){
      let show = JSON.parse(JSON.stringify(this.placeIns))
      show = []
      show[id] = !show[id]
      this.placeIns = show
   },
   placefalse(id){
     let show = JSON.parse(JSON.stringify(this.placeIns))
      show = [false]
      // show[id] = !show[id]
      this.placeIns = show
   },
    async construction () { // 施工流程
      const res = await request({
        url: '/api/index/course?type=7'
      })
      this.consArr = res.data.data
      const lh = this.consArr.length
      if (lh >= 5 && lh % 2 === 1){
        this.isTrue = false
      } else {
        this.isTrue = true
      }
   },
    async fetchCaseList () {
      const res = await request({
        url: '/api/index/caselist'
      })
      const cases = (res.data.data || []).map(i => {
        i.images = `${imgHttp}${i.images}`
        return i
      })
      const casesWithImages = cases.splice(0, MAX_CASES_WITH_IMAGE)

      // 分成两列
      // noinspection JSValidateTypes
      this.casesWithImages = casesWithImages.reduce((p, c, i) => {
        p[i % 2].push(c)
        return p
      }, [[], []])
      this.cases = cases
    },
    async bigBanner () {
      const res = await request({
        url: '/api/index/cover?type=3'
      })
       const bannerNewArr = res.data.data.map(i => {
        if (i.images !== ''){
            i.images = i.images.split(',').map(r => {
                r = imgHttp + r
                return r
            })
          }
          if (i.videos !== ''){
              i.videos = i.videos.split(',').map(r => {
              r = imgHttp + r
              return r
          })
          }
          i.arr = [...i.images, ...i.videos]
        return i
      })
        this.coverBanner = bannerNewArr[0].arr
   },
   locate(){
       window.location.href = '#caseBox'
   }
  },
  mounted() {
     if (this.$route.query.active){
      this.locate()
     }
  }
}
</script>

<style lang="less" scoped>
  @import '../fn.less';
  @import '../../style/box.css';

@keyframes cases-float-gear {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(0.520833vw);
  }
  100% {
    transform: translateY(0);
  }
}

.cases-wrapper {
  @import "../../style/common.less";
  .full-with-banner{
    width: 100%;
    height: 46vw;
    position: relative;
    .homebanner{
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .construction-process-wrapper {
    .modile{
      display: none;
    }
    margin-top: 10.58333vw;
    .processes-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      .prize-image{
        .vw(width,430);
        .vw(height,200);
        .vw(padding,37,60,40);
        box-sizing: border-box;
        position: relative;
        transition: .25s;
        &:nth-child(2n-1){
          color: #FFFFFF;
          background: url('../../assets/cases/arrow-3.png') no-repeat;
         background-size: 100% 100%;
        }
        &:nth-child(2n){
          color: #00538D;
          background: url('../../assets/cases/arrow-2.png') no-repeat;
        background-size: 100% 100%;
        }
        &:first-child{
        background: url('../../assets/cases/arrow-1.png') no-repeat;
        background-size: 100% 100%;
        .vw(padding-left,30)
        }
        &:last-child{
        background: url('../../assets/cases/arrow-4.png') no-repeat;
        background-size: 100% 100%;
        }
        .process-name{
          font-weight: 700;
          .vw(font-size,30)
        }
        .process-description{
          .vw(font-size,16);
          .vw(margin-top,24);
          display:-webkit-box; -webkit-box-orient:vertical; -webkit-line-clamp:3; overflow:hidden;
          &:hover{
            cursor: pointer;
          }
        }
        .prizeInfo{
          top: 9vw;
          right: 3vw;
          position: absolute;
          width: 13vw;
          min-height: 5vw;
          color: #fff;
          background: rgba(0, 83, 141, .8);
          padding: 0.5vw;
          border-radius: 0.5vw;
          box-sizing: border-box;
          z-index: 10000;
          font-size: 0.8vw;
          .icon{
            display: block;
            width: 0;
            height: 0;
            border-right: 0.5vw solid transparent;
            border-left: 0.5vw solid transparent;
            border-bottom: 0.5vw solid rgba(0, 83, 141, .8);
            position: absolute;
            top: -0.4vw;
            left: 0.8vw;
          }
        }
        &:hover{
          transform: scale(1.05);
        }
      }
      .bgimg:last-child{
        background: url('../../assets/cases/arrow-5.png') no-repeat;
        background-size: 100% 100%;
      }
    }
  }

  .quality-assurance-wrapper {
    margin-top: 5.197916vw;
    position: relative;
    .float-image {
      position: absolute;
      width: 11.822916vw;
      height: 11.822916vw;
      left: 14.58333vw;
      top: 4.42708333vw;
      animation-name: cases-float-gear;
      animation-duration: 3s;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
      &.shield {
        animation-delay: .5s;
        width: 4.84375vw;
        height: 5.83333vw;
        left: unset;
        right: 11.9791667vw;
        top: 15.9375vw;
      }
    }

    .guarantees-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      padding-top: 7.8125vw;
      .guarantee-wrapper {
        width: 17.8125vw;
        height: 20.104166vw;
        background-color: #DDF0FE;
        border-radius: 2.708333vw;
        text-align: center;
        padding: 2.604166vw 3.125vw;
        border: 0.520833vw solid #BDE3FF;
        white-space: pre-wrap;
        cursor: default;
        transition: .25s;
        margin: 0 0.2604166vw;
        .icon {
          img {
            height: 5.729166vw;
            width: 5.729166vw;
          }
        }
        .name {
          padding: 1.8229166vw 0;
          font-size: 2.3958333vw;
          font-weight: bold;
          color: #171B1D;
        }
        .desc {
          font-size: 1.04166vw;
          font-weight: 400;
          color: #6C7388;
          line-height: 1.92708333vw;
        }
        &:hover {
          transform: scale(1.2);
          background-color: white;
          z-index: 10;
        }
      }
    }
  }

  .scope-of-work-wrapper {
    margin-top: 10.92708333vw;
    position: relative;
    .scope-of-work-wrapper-background-placeholder {
      position: absolute;
      top: 0;
      left: 0;
      transform: translateY(-55%);
      width: 100%;
      height: 104.16666vw;
      z-index: -1;
      background: linear-gradient(rgba(0, 0, 0, 0), rgba(140, 203, 255, .2), rgba(0, 0, 0, 0));
    }
    .abilities-wrapper {
      margin-top: 5.46875vw;
      display: flex;
      justify-content: space-evenly;
      align-items: flex-start;
      .ability-wrapper {
        width: 6.7291666vw;
        cursor: default;
        transition: .25s;
        display: flex;
        flex-direction: column;
        .icon {
          width: 100%;
          height: 7.2395833vw;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .name {
          margin-top: -1.04166vw;
          font-size: 0.9375vw;
          font-weight: bold;
          color: #6C7388;
          line-height: 1.5104166vw;
          text-align: center;
        }
        &:hover {
          transform: scale(1.2);
        }
      }
    }
  }

  .demonstrations-wrapper {
    margin-top: 10.9375vw;
    .demonstration-columns {
      display: flex;
      padding: 1.6666vw;
      justify-content: center;
      align-items: flex-start;
      .demonstration-column {
        margin-top: 1.8229166vw;
        .demonstration-wrapper {
          width: 30.104166vw;
          position: relative;
          margin: 0 1.6666vw 5.7291666vw;
          cursor: pointer;
          transition: .25s;
          .image {
            width: 100%;
          }
          .desc-wrapper {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 60%;
            padding: 2.08333vw 2.86458333vw 2.86458333vw;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            background-color: rgba(255, 255, 255, .9);
            transition: .25s;
            .name {
              font-size: 1.5626vw;
              font-weight: bold;
              color: #101218;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            }
            .brief-desc {
              margin-top: 0.78125vw;
              font-size: 1.04166vw;
              font-weight: 400;
              color: #6C7388;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            }
          }
          &:hover {
            box-shadow: 0 0 1.04166vw 0 rgba(0, 0, 0, 0.3);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            // .image{
            //   width: 110%;
            // }
            .desc-wrapper {
              // width: calc(100% - 55px * 2);
              background-color: white;
            }
          }
        }
        .more-demos-wrapper {
          width: 30.104166vw;
          padding-left: 1.6666vw;
          .title {
            font-size: 1.5626vw;
            font-weight: bold;
            color: #101218;
            line-height: 1.8229166vw;
          }
          .sep {
            height: 1px;
            background: #E5E7ED;
            margin: 2.08333vw 0;
          }
          .more-demos {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin: 0 -1.3020833vw;
            .more-demo {
              display: inline-block;
              white-space: nowrap;
              font-size: 1.04166vw;
              font-weight: 400;
              color: #6C7388;
              line-height: 1.8229166vw;
              padding: 0 1.3020833vw;
              margin-bottom: 2.08333vw;
              cursor: pointer;
              transition: .25s;
              &:hover {
                transform: scale(1.2);
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 980px) {
  .full-with-banner{
    .homebanner{
      margin-top: 5vw;
    }
  }
.cases-wrapper {
  .full-with-banner{
    img{
      height: 50vw;
    }
  }
  .construction-process-wrapper {
    margin-top: 10.58333vw;
    .pc{
      display: none;
    }
    .modile{
      display: block;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
        .vvw(margin-top,60);
      .modile_box{
        .vvw(width,440);
        .vvw(min-height,240);
        .vvw(padding,20 30 20 60);
        .vvw(margin-bottom,20);
        box-sizing: border-box;
        &:nth-child(2n-1){
          color: #FFFFFF;
          background: url('../../assets/cases/arrow-3.png') no-repeat;
         background-size: 100% 100%;
        }
        &:nth-child(2n){
          color: #00538D;
          background: url('../../assets/cases/arrow-2.png') no-repeat;
        background-size: 100% 100%;
        }
        &:first-child{
        background: url('../../assets/cases/arrow-1.png') no-repeat;
        background-size: 100% 100%;
        color:#fff;
        .vvw(padding-left,20);
        }
        &:last-child{
        background: url('../../assets/cases/arrow-4.png') no-repeat;
        background-size: 100% 100%;
        }
        .process-name{
          .vvw(font-size,35);
          .vvw(margin-bottom,20);
          font-weight: 700;
        }
        .process-description{
          .vvw(font-size,28);
        }
        &:hover{
          transform: scale(1.05);
        }
      }
      .bgimg:last-child{
        background: url('../../assets/cases/arrow-5.png') no-repeat;
        background-size: 100% 100%;
        color: #fff;
      }
    }
  }
  .quality-assurance-wrapper {
    margin-top: 0vw;
    position: relative;
    .float-image {
      display: none;
    }

    .guarantees-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      padding-top: 7.8125vw;
      .guarantee-wrapper {
        width: 34.8125vw;
        .vvw(min-height,550);
        padding: 2.604166vw 3.125vw;
        margin: 0 0.8604166vw;
        .icon {
          img {
            height:10.729166vw;
            width: 10.729166vw;
          }
        }
        .name {
          padding: 1.8229166vw 0;
        }
        .desc {
          .vvw(font-size,24);
          line-height: 4.92708333vw;
        }
        &:hover {
          transform: scale(1.02);
        }
      }
    }
  }
  .scope-of-work-wrapper {
    margin-top: 10.92708333vw;
    position: relative;
    .scope-of-work-wrapper-background-placeholder {
      width: 100%;
      height: 104.16666vw;
    }
    .abilities-wrapper {
      margin-top: 18.46875vw;
      display: flex;
      justify-content: space-evenly;
      align-items: flex-start;
      .ability-wrapper {
        width: 19.7291666vw;
        height: 25.7291666vw;
        margin-bottom: 0vw;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        .icon {
          width: 100%;
          height: 22.7291666vw;
        }
        .name {
          margin-top: 0;
          .vvw(font-size,24);
          line-height: 4.5104166vw;
        }
        &:hover {
          transform: scale(1.05);
        }
      }
    }
  }
  .demonstrations-wrapper {
    margin-top: 10.9375vw;
    .demonstration-columns {
      .demonstration-column {
        margin-top: 5.8229166vw;
        .demonstration-wrapper{
          .desc-wrapper{
          .name{
          .vvw(font-size,24);
          }
          .brief-desc{
            .vvw(font-size,24);
          }
          }
        }
      }
      .demonstration-column{
      .more-demos-wrapper {
          width: 30.104166vw;
          padding-left: 1.6666vw;
          .title {
          .vvw(font-size,40);
            font-weight: bold;
            color: #101218;
            line-height: 1.8229166vw;
          }
          .sep {
            height: 1px;
            background: #E5E7ED;
            margin: 2.08333vw 0;
          }
          .more-demos {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin: 0 -1.3020833vw;
            .more-demo {
              display: inline-block;
              white-space: nowrap;
          .vvw(font-size,24);
              font-weight: 400;
              color: #6C7388;
              line-height: 1.8229166vw;
              padding: 0 1.3020833vw;
              margin-bottom: 2.08333vw;
              cursor: pointer;
              transition: .25s;
              &:hover {
                transform: scale(1.2);
              }
            }
          }
        }
      }
    }
  }
  }
}
</style>
<style lang="less" scoped>
  .swiper-container{
    height: 20vw;
    padding-top: 10vw;
  }
  @media screen and (max-width: 980px){
    .swiper-container{
      min-height: 35vw;
      padding-top: 0vw;
    }
  }
</style>
